import * as React from "react";
import { graphql } from "gatsby";
import toast, { Toaster } from "react-hot-toast";
import {
  Link,
  useTranslation,
  Trans,
  useI18next,
} from "gatsby-plugin-react-i18next";
import { useForm, Controller } from "react-hook-form";
import { motion } from "framer-motion";
import { useLocation } from "@reach/router";
import { window } from "browser-monads";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";

// Assets
import Section1Item1Icon from "../images/icons/contact-section1-1.svg";
import Section1Item2Icon from "../images/icons/contact-section1-2.svg";
import Section1Item3Icon from "../images/icons/contact-section1-3.svg";
import SuccessIcon from "../images/icons/contact-success.svg";

// Styles
import * as styles from "../styles/pages/contact.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";
import styled from "styled-components";
import { api } from "../utils/api";
import ContactUsGradient from "../components/ContactUsGradient";

export const StyledSelect = styled(Select)`
  .Select__control {
    height: 40px;
    width: 100%;
    border: 1px solid #a1a1a1;
    border-radius: 0;
    cursor: pointer;
  }

  .Select__control:hover {
    border-color: #a1a1a1;
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    color: red;
    background: black;
    z-index: 50;
  }
`;
export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "var(--brand-blue)" : "white",
    // Hover
    ":hover": {
      backgroundColor: !state.isSelected && "#f2f3f7",
    },
    zIndex: 9999,
  }),
  control: (provided, state) => ({
    ...provided,
    height: 48,
    borderWidth: 2,
    borderColor: state.selectProps.hasError
      ? "var(--brand-red)"
      : "transparent",
    borderRadius: 6,
    position: "relative",
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 16,
  }),
  menu: (provided) => ({
    ...provided,
    position: "absolute",
    zIndex: 1,
  }),
};

const Section1 = () => {
  const { t } = useTranslation();
  const Section1Data = [
    {
      icon: <Section1Item1Icon />,
      text: t("contact.section1.item1.text"),
    },
    {
      icon: <Section1Item2Icon />,
      text: t("contact.section1.item2.text"),
    },
    {
      icon: <Section1Item3Icon />,
      text: t("contact.section1.item3.text"),
    },
  ];
  return (
    <section className={styles.section1}>
      <ContactUsGradient />
      <div className={`${styles.section1__content__wrapper} sectionContainer`}>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={`${styles.section1__content}`}
        >
          <motion.h1
            variants={revealVariant}
            className={styles.section1__title}
          >
            <Trans
              i18nKey="contact.section1.title"
              values={{ highlight: t("contact.section1.title.highlight") }}
              components={[<span />]}
            />
          </motion.h1>
          <motion.p
            variants={revealVariant}
            className={styles.section1__description}
          >
            {t("contact.section1.description")}
          </motion.p>
          <div variants={revealVariant} className={styles.section1__items}>
            {Section1Data.map((item, index) => (
              <motion.div
                variants={revealVariant}
                className={styles.section1__item}
                key={index}
              >
                <div className={styles.section1__item__icon}>{item.icon}</div>
                <p className={styles.section1__item__text}>{item.text}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
        <FormSection />
      </div>
    </section>
  );
};

export const FormSection = () => {
  const { t, language } = useI18next();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const location = useLocation();
  const fullUrl = location.state?.referrer || location.href;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await fetch(`${api}/v3/contact-us`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
      body: JSON.stringify({
        company_name: data.company,
        name: data.fullName,
        phone: data.phone,
        email: data.email,
        description: data.message,
        url: fullUrl
      }),
    });
    if (response.status === 200) {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: "formSubmitted",
          formName: "LG_contactUs",
          formPosition: "body",
        });
      }
      setIsFormSubmitted(true);
    } else {
      try {
        const res = await response?.json();
        const errorMessages = Object.values(res?.errors || {});

        toast.error(
          errorMessages?.[0] || res?.message || "Something went wrong"
        );
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
    setIsLoading(false);
  };
  if (isFormSubmitted) {
    return (
      <section className={`${styles.formSuccessSection} sectionContainer`}>
        <div className={styles.formSuccessSection__icon}>
          <SuccessIcon />
        </div>
        <h2 className={styles.formSuccessSection__title}>
          {t("contact.form.success.title")}
        </h2>
        <p className={styles.formSuccessSection__description}>
          {t("contact.form.success.description")}
        </p>
        <Link to="/" className="button button--secondary">
          {t("contact.form.success.button")}
        </Link>
      </section>
    );
  }
  return (
    <motion.section
      {...revealContainerProps}
      variants={revealVariant}
      className={`${styles.formSection}`}
    >
      <motion.form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formSection__form}>
          <div
            {...revealContainerWithChildrenProps}
            className={`${styles.formSection__form__col} sm:max-lg:!grid sm:max-lg:!grid-cols-2`}
          >
            <div variants={revealVariant}>
              <label
                htmlFor="fullName"
                className={styles.formSection__form__item__label}
              >
                {t("contact.formSection.form.fullName.label")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="fullName"
                placeholder={t("contact.formSection.form.fullName.placeholder")}
                className={`input  ${errors.fullName ? "error" : ""}`}
                {...register("fullName", {
                  required: true,
                  pattern: /^[a-zA-Z ]*$/,
                })}
              />
              {errors.fullName && (
                <span className={styles.formSection__form__error}>
                  {errors.fullName.type === "required"
                    ? t("contact.formSection.form.error.required")
                    : t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.name.field"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </div>
            <div variants={revealVariant}>
              <label
                htmlFor="phone"
                className={styles.formSection__form__item__label}
              >
                {t("contact.formSection.form.phone.label")}
                <span>*</span>
              </label>
              <input
                placeholder={t("contact.formSection.form.phone.placeholder")}
                type="text"
                id="phone"
                className={`input  ${errors.phone ? "error" : ""}`}
                {...register("phone", {
                  required: true,
                  pattern:
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                })}
              />
              {errors.phone && (
                <span className={styles.formSection__form__error}>
                  {errors.phone.type === "required" &&
                    t("contact.formSection.form.error.required", {
                      field: t(
                        "contact.formSection.form.phone.label"
                      ).toLowerCase(),
                    })}
                  {errors.phone.type === "pattern" &&
                    t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.phone.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </div>
            <div variants={revealVariant}>
              <label
                htmlFor="email"
                className={styles.formSection__form__item__label}
              >
                {t("contact.formSection.form.email.label")}
                <span>*</span>
              </label>
              <input
                placeholder={t("contact.formSection.form.email.placeholder")}
                type="email"
                id="email"
                className={`input  ${errors.email ? "error" : ""}`}
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className={styles.formSection__form__error}>
                  {errors.email.type === "required"
                    ? t("contact.formSection.form.error.required")
                    : t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.email.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </div>
            <div variants={revealVariant}>
              <label
                htmlFor="company"
                className={styles.formSection__form__item__label}
              >
                {t("contact.formSection.form.company.label")}
                <span>*</span>
              </label>
              <input
                placeholder={t("contact.formSection.form.company.placeholder")}
                type="text"
                id="company"
                className={`input  ${errors.company ? "error" : ""}`}
                {...register("company", { required: true })}
              />
              {errors.company && (
                <span className={styles.formSection__form__error}>
                  {errors.company.type === "required"
                    ? t("contact.formSection.form.error.required")
                    : t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.company.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </div>


            <div
              variants={revealVariant}
              className={` ${styles.formSection__form__item__textarea__wrapper} sm:max-lg:col-span-2`}
            >
              <label
                htmlFor="message"
                className={styles.formSection__form__item__label}
              >
                {t("contact.formSection.form.message.label")}
                <span>*</span>
              </label>
              <textarea
                id="message"
                htmlFor="message"
                placeholder={t("contact.formSection.form.message.placeholder")}
                className={`textarea ${styles.formSection__form__item__textarea
                  } ${errors.message ? "error" : ""}`}
                {...register("message", {
                  required: true,
                  minLength: 10,
                  maxLength: 200,
                })}
              />
              {errors.message && (
                <span className={styles.formSection__form__error}>
                  {errors.message.type === "required" &&
                    t("contact.formSection.form.error.required")}
                  {errors.message.type === "minLength" &&
                    t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.message.min.field"
                      ).toLowerCase(),
                    })}
                  {errors.message.type === "maxLength" &&
                    t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.message.max.field"
                      ).toLowerCase(),
                    })}
                </span>
              )}

              {errors.phone && (
                <span className={styles.formSection__form__error}>
                  {errors.phone.type === "required" &&
                    t("contact.formSection.form.error.required", {
                      field: t(
                        "contact.formSection.form.phone.label"
                      ).toLowerCase(),
                    })}
                  {errors.phone.type === "pattern" &&
                    t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.phone.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </div>
            <motion.button
              variants={revealVariant}
              onClick={window?.dataLayer?.push?.({ event: "submit" })}
              type="submit"
              className={`button button--secondary ${styles.formSection__form__submit} sm:max-lg:col-span-2`}
            >
              {isLoading ? (
                <ThreeDots
                  height="28"
                  width="28"
                  color="#fff"
                  ariaLabel="loading"
                  radius="3"
                  visible={true}
                />
              ) : (
                t("contact.formSection.form.submit")
              )}
            </motion.button>
          </div>
        </div>
      </motion.form>
    </motion.section>
  );
};

const ContactPage = () => {
  return (
    <Layout>
      <Section1 />
      <Toaster />
    </Layout>
  );
};

export default ContactPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "contact"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return (
    <Seo isArabic={isArabic} title={isArabic ? "اتصل بنا" : "Contact Us"} />
  );
};
