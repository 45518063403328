import React from "react";

// Styles
import * as styles from "../styles/components/ContactUsGradient.module.css";

const ContactUsGradient = ({ children }) => {
  return <div className={styles.sectionContainer}>{children}</div>;
};

export default ContactUsGradient;
